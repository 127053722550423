html.dark {
  background-color: rgb(15, 23, 42);
}

.biblia-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 1rem;
}

.biblia-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.biblia-grid {
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  margin-top: 1.5rem;
  gap: 1rem;
}

.biblia-livrosBox {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.biblia-livrosColuna {
  columns: 2;
  flex-grow: 1;
  column-count: 2;
  margin: 0;
  padding: 0;
  list-style: none outside none;
}

.biblia-card,
.biblia-cardDark {
  margin: 0.5rem;
  padding: 0.5rem 1.5rem;
  /* text-align: center; */
  color: inherit;
  text-decoration: none;
  border-color: #d1d1d1;
  border-radius: 10px;
  text-transform: none !important;
  border: none !important;
  justify-content: flex-start;
}

.biblia-card:hover,
.biblia-card:focus,
.biblia-card:active {
  transition: color 0.15s ease, border-color 0.15s ease;
  color: #0070f3;
  border-color: #0070f3;
}

.biblia-cardDark:hover,
.biblia-cardDark:focus,
.biblia-cardDark:active {
  transition: color 0.15s ease, border-color 0.15s ease;
  color: var(--p-light);
  border-color: var(--p-light) !important;
}

@media (max-width: 500px) {
  .biblia-grid {
    width: 100vw;
    flex-direction: column;
    max-width: none;
    align-items: normal;
  }
  .biblia-booksSection {
    width: 100%;
  }
  .biblia-card,
  .biblia-cardDark {
    width: 70vw;
  }
}
